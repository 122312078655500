const region = 'eu-central-1';
const userPoolId = 'eu-central-1_80VynoGj5';
const userPoolWebClientId = '3cctuq1sb5ooe0ahffic2so54v';
const identityPoolId = 'eu-central-1:405b51f0-9e94-44b3-b996-47665429ce78';

export const environment = {
  production: false,
  apiUrl: 'https://demo-integrations-test.powerdata.de/il-backend',
  clientName: 'demo-integrations-test',
  dashboardItemsCount: 2,
  sftpHost: 's-e9e1b242bd48453b9.server.transfer.eu-central-1.amazonaws.com',
  sftpPort: 22,
  AWS: {
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'en',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
